import { ChakraProvider } from '@chakra-ui/react'

import { AppProvider } from 'hooks'

import { CoreRouter } from 'app/core/routes'
import theme from 'config/theme'

import ErrorBoundary from './error-boundary'

const App = (): JSX.Element => (
  <ErrorBoundary displayMessage="An unexpected error occurred.">
    <ChakraProvider theme={theme}>
      <AppProvider>
        <div>
          <CoreRouter />
        </div>
      </AppProvider>
    </ChakraProvider>
  </ErrorBoundary>
)

export default App
