import { useCallback, useEffect, useState } from 'react'

import { handleHttpError } from 'utils/http'

import {
  getUserByOldAddress,
  postUserUpgrade,
  softDeleteUser,
} from 'app/auth/services/user'
import { UserHook } from 'app/core/types/hooks'
import { UpgradeStatus, UserResponseData } from 'app/core/types/user'
import { StellarWallet } from 'interfaces/stellar-wallet'
import { AxiosError } from 'axios'

const useUser = (userOldNetworkAddress?: string): UserHook => {
  const [currentUser, setCurrentUser] = useState<UserResponseData>()
  const [upgradeStatus, setUpgradeStatus] = useState<UpgradeStatus>(
    UpgradeStatus.inProgress
  )
  const [isLoading, setIsLoading] = useState(false)

  const getUser = useCallback(async (): Promise<void> => {
    if (userOldNetworkAddress) {
      try {
        const user = await getUserByOldAddress(userOldNetworkAddress)
        setCurrentUser(user)
      } catch (e) {
        handleHttpError(e, false)
      }
    }
  }, [userOldNetworkAddress])

  const postUpgradeUser = async (
    confirmationToken: string,
    wallet?: StellarWallet
  ): Promise<void> => {
    if (userOldNetworkAddress && wallet) {
      try {
        await postUserUpgrade({
          oldAddress: userOldNetworkAddress,
          stellarWallet: wallet,
          code: confirmationToken,
        })
        setUpgradeStatus(UpgradeStatus.successful)
      } catch (e) {
        if (e instanceof AxiosError && e.response?.status === 403) {
          setUpgradeStatus(UpgradeStatus.forbidden);
          return;
        }
        setUpgradeStatus(UpgradeStatus.failed)
        handleHttpError(e, false)
      }
    }
  }

  const deleteUser = async (): Promise<void> => {
    if (userOldNetworkAddress) {
      try {
        setIsLoading(true)
        await softDeleteUser(userOldNetworkAddress)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        handleHttpError(e, true)
      }
    }
  }

  useEffect(() => {
    getUser()
  }, [getUser])

  return {
    getUser,
    postUpgradeUser,
    deleteUser,
    currentUser,
    upgradeStatus,
    isLoading,
  }
}

export { useUser }
