import { StorageKeys } from 'app/core/types/storage'

const logout = (): void => {
  sessionStorage.clear()
}

const setToken = (token: string): void => {
  sessionStorage.setItem(StorageKeys.auth_token, token)
}

const setMainData = (mainData: string): void => {
  sessionStorage.setItem(StorageKeys.main_data, mainData)
}

const setKeychainData = (keychainData: string): void => {
  sessionStorage.setItem(StorageKeys.keychain_data, keychainData)
}

const setUserOldAddress = (oldAddress: string): void => {
  sessionStorage.setItem(StorageKeys.old_address, oldAddress)
}

const setUserEmail = (email: string): void => {
  sessionStorage.setItem(StorageKeys.email, email)
}

const getToken = (): string => {
  return sessionStorage.getItem(StorageKeys.auth_token) || ''
}

const getTokenPayload = (jwt: string): Hooks.UseAuthTypes.IJwt | null => {
  try {
    return JSON.parse(atob(jwt.split('.')[1]).toString())
  } catch (error) {
    return null
  }
}

const getUserFromJWT = (): Hooks.UseAuthTypes.IUser | null => {
  const token = getToken()
  if (!token) {
    return null
  }
  const user = getTokenPayload(token)
  if (user?.exp && user.exp * 1000 > Date.now()) {
    return {
      username: user.name,
    }
  } else {
    logout()
    return null
  }
}

const getOldAddress = (): string => {
  return sessionStorage.getItem(StorageKeys.old_address) || ''
}

const getUserEmail = (): string => {
  return sessionStorage.getItem(StorageKeys.email) || ''
}

const Authentication = {
  getUserFromJWT,
  getToken,
  getOldAddress,
  getUserEmail,
  logout,
  setToken,
  setMainData,
  setKeychainData,
  setUserOldAddress,
  setUserEmail,
}

export default Authentication
