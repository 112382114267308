import { Alert, AlertIcon, CloseButton, Flex, Text } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { formatSTR } from 'utils/formatter'

import { PathRoute } from 'components/enums/path-route'
import { ArrowUpIcon } from 'components/icons'
import { ConfirmSeed } from 'components/molecules/confirm-seed'
import { GenerateSeed } from 'components/molecules/generate-seed'
import { InstallFreighter } from 'components/molecules/install-freighter'
import { StartUpgrade } from 'components/molecules/start-upgrade'
import { UpgradeAccount } from 'components/molecules/upgrade-account'
import { VerificationCode } from 'components/molecules/verification-code'

import { UpgradeStatus, UserResponseData } from 'app/core/types/user'

interface IUpgradeTemplate {
  user?: UserResponseData
  userEmail: string
  seeds: string[]
  upgradeStatus: UpgradeStatus
  upgradeUser: () => void
  sendConfirmationEmail: () => void
  verifyConfirmationEmail: (confirmationCode: string) => void
  timeToResendEmail: number
  isLoading: boolean
}

export enum Steps {
  startUpgrade = 'startUpgrade',
  confirmCode = 'confirmCode',
  generateSeed = 'generateSeed',
  confirmSeed = 'confirmSeed',
  installFreighter = 'installFreighter',
  startProcess = 'startProcess',
}
export interface IUpgradeStep {
  nextStep: () => void
  previousStep?: () => void
}

export const UpgradeTemplate: React.FC<IUpgradeTemplate> = ({
  user,
  userEmail,
  seeds,
  upgradeUser,
  upgradeStatus,
  sendConfirmationEmail,
  verifyConfirmationEmail,
  timeToResendEmail,
  isLoading,
}) => {
  const [selectedStep, setSelectedStep] = useState<Steps>(Steps.startUpgrade)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()
  const needSendEmail = !!userEmail

  const startUpgrade: IUpgradeStep = {
    nextStep: (): void =>
      setSelectedStep(needSendEmail ? Steps.confirmCode : Steps.generateSeed),
  }

  const confirmCode: IUpgradeStep = {
    nextStep: (): void => setSelectedStep(Steps.generateSeed),
  }

  const generateSeed: IUpgradeStep = {
    nextStep: (): void => setSelectedStep(Steps.confirmSeed),
  }

  const confirmSeed: IUpgradeStep = {
    nextStep: (): void => setSelectedStep(Steps.installFreighter),
    previousStep: (): void => setSelectedStep(Steps.generateSeed),
  }

  const installFreighter: IUpgradeStep = {
    nextStep: (): void => setSelectedStep(Steps.startProcess),
  }

  const balanceInSTR = useMemo(() => {
    if (user) {
      return formatSTR(user.str_balance).toString()
    }
  }, [user])

  return (
    <Flex w="full" direction="column" p="72px 40px" alignItems="center">
      <Flex pt="62px" direction="column" alignItems="flex-start" gap="12px">
        {error && (
          <Flex w="100%" direction="column">
            <Alert
              zIndex="99"
              status="error"
              p="8px 16px"
              justifyContent="space-between"
            >
              <Flex>
                <AlertIcon />
                {error}
              </Flex>
              <CloseButton
                onClick={(): void => setError(null)}
                data-testid="close-button"
              />
            </Alert>
          </Flex>
        )}
        <Flex gap="9px" alignItems="flex-end">
          <ArrowUpIcon width="36px" height="36px" />
          <Text fontSize="x-large" fontWeight="bold">
            Account upgrade
          </Text>
        </Flex>

        {selectedStep == Steps.startUpgrade && (
          <StartUpgrade
            balanceInSTR={balanceInSTR}
            balanceInXLM={balanceInSTR}
            step={startUpgrade}
            needSendEmail={needSendEmail}
            setError={setError}
            sendConfirmationEmail={sendConfirmationEmail}
            isLoading={isLoading}
          />
        )}
        {selectedStep == Steps.confirmCode && (
          <VerificationCode
            email={userEmail}
            step={confirmCode}
            onCancelUpgradeClick={(): void => navigate(PathRoute.HOME)}
            setError={setError}
            timeToResendEmail={timeToResendEmail}
            sendConfirmationEmail={sendConfirmationEmail}
            verifyConfirmationEmail={verifyConfirmationEmail}
            isLoading={isLoading}
          />
        )}
        {selectedStep == Steps.generateSeed && (
          <GenerateSeed step={generateSeed} seeds={seeds} />
        )}
        {selectedStep == Steps.confirmSeed && (
          <ConfirmSeed step={confirmSeed} seeds={seeds} setError={setError} />
        )}
        {selectedStep == Steps.installFreighter && (
          <InstallFreighter
            step={installFreighter}
            onCancelUpgradeClick={(): void => navigate(PathRoute.HOME)}
            upgradeUser={upgradeUser}
          />
        )}
        {selectedStep == Steps.startProcess && (
          <UpgradeAccount
            upgradeStatus={upgradeStatus}
            onClickGoBack={(): void => navigate(PathRoute.HOME)}
          />
        )}
      </Flex>
    </Flex>
  )
}
