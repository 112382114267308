/* eslint-disable @typescript-eslint/no-explicit-any */
import base64 from 'base-64'
import { Buffer } from 'buffer'
import CryptoJS from 'crypto-js'
import { scrypt } from 'scrypt-js'
import sjcl from 'sjcl'
import { StrKey } from 'stellar-sdk'
import nacl from 'tweetnacl'

const WALLET_KEY_TOKEN = process.env.REACT_APP_WALLET_KEY_TOKEN || 'WALLET_KEY'

type KdfParams = {
  n: number
  r: number
  p: number
  bits: number
}

export type EncryptedData = {
  encryptedMainData: string
  encryptedKeychainData: string
  salt: string
  username: string
  password: string
  kdfParams: KdfParams
}

export type DecryptedUserData = {
  mainData: string
  keychainData: string
}

export const decryptUserData = async ({
  encryptedMainData,
  encryptedKeychainData,
  salt,
  username,
  password,
  kdfParams,
}: EncryptedData): Promise<DecryptedUserData> => {
  const rawMasterKey = await calculateMasterKey(
    salt,
    addStellarEmail(username),
    password,
    kdfParams
  )
  const rawWalletKey = deriveWalletKey(rawMasterKey)

  const mainData = decryptData(encryptedMainData, rawWalletKey)
  const keychainData = decryptData(encryptedKeychainData, rawWalletKey)

  return { mainData, keychainData }
}

function deriveWalletKey(masterKey: Uint8Array): Uint8Array {
  const keyWordArray = CryptoJS.lib.WordArray.create(masterKey)
  const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, keyWordArray)
  hmac.update(CryptoJS.enc.Utf8.parse(WALLET_KEY_TOKEN))
  const hashWordArray = hmac.finalize()

  const hashArray = new Uint8Array(
    hashWordArray.words
      .map(word => [
        (word >> 24) & 0xff,
        (word >> 16) & 0xff,
        (word >> 8) & 0xff,
        word & 0xff,
      ])
      .flat()
  )

  const aesKeySize = 32
  const aesKey = hashArray.slice(0, aesKeySize)

  return aesKey
}

export const decryptData = (encryptedData: string, key: Uint8Array): string => {
  let rawCipherText, rawIV, rawData

  try {
    const resultObject = JSON.parse(base64Decode(encryptedData))
    rawIV = sjcl.codec.base64.toBits(resultObject.IV)
    rawCipherText = sjcl.codec.base64.toBits(resultObject.cipherText)

    const words = []
    for (let i = 0; i < key.length; i += 4) {
      words.push(
        (key[i] << 24) | (key[i + 1] << 16) | (key[i + 2] << 8) | key[i + 3]
      )
    }

    const cipher = new sjcl.cipher.aes(words)
    rawData = sjcl.mode.gcm.decrypt(cipher, rawCipherText, rawIV)
  } catch (err) {
    throw new Error(err as string)
  }
  return sjcl.codec.utf8String.fromBits(rawData)
}

function base64Decode(str: string): string {
  return Buffer.from(str, 'base64').toString('utf-8')
}

async function calculateMasterKey(
  s0: string,
  username: string,
  password: string,
  kdfParams: KdfParams
): Promise<Uint8Array> {
  try {
    const versionBits = CryptoJS.enc.Hex.parse('01')
    const s0Bits = CryptoJS.enc.Base64.parse(s0)
    const usernameBits = CryptoJS.enc.Utf8.parse(username)
    const unhashedSaltBits = versionBits.concat(s0Bits).concat(usernameBits)
    const salt = CryptoJS.SHA256(unhashedSaltBits)

    const saltArray = new Uint8Array(
      salt.words
        .map(word => [
          (word >> 24) & 0xff,
          (word >> 16) & 0xff,
          (word >> 8) & 0xff,
          word & 0xff,
        ])
        .flat()
    )

    const key = await scrypt(
      new TextEncoder().encode(password),
      saltArray,
      4096,
      8,
      1,
      256 / 8
    )
    return key
  } catch (err) {
    throw new Error(err as string)
  }
}

function addStellarEmail(username: string): string {
  return username.concat('@stellar.org')
}

export async function generateMessage(
  oldNetworkAddress: string,
  newNetworkPublicKey: string,
  upgradeMessageSecret: string
): Promise<string> {
  try {
    const encoder = new TextEncoder()
    const data = encoder.encode(
      oldNetworkAddress + newNetworkPublicKey + upgradeMessageSecret
    )

    const hashBuffer = await crypto.subtle.digest('SHA-256', data)

    // Convert the hash buffer to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const message = hashArray
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('')

    return message
  } catch (error) {
    throw new Error(`Error generating message: ${error}`)
  }
}

export function signMessage(secretKey: string, message: string): string {
  try {
    // Decode the Stellar secret key to ed25519 private key
    const privateKeySeed = StrKey.decodeEd25519SecretSeed(secretKey)

    // Generate the ed25519 key pair from the private key seed
    const keyPair = nacl.sign.keyPair.fromSeed(new Uint8Array(privateKeySeed))
    const privateKey = keyPair.secretKey

    // Sign the message
    const messageBytes = new TextEncoder().encode(message)
    const signature = nacl.sign.detached(messageBytes, privateKey)

    // Encode the signature to base64
    const signatureBase64 = base64.encode(
      String.fromCharCode.apply(null, signature as any)
    )

    return signatureBase64
  } catch (error) {
    throw new Error(`Error generating signature: ${error}`)
  }
}
