import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { EmailIcon } from 'components/icons'

import { IUpgradeStep } from 'app/core/pages/upgrade/template'

import { CustomContainer } from '../custom-container'

interface IVerificationCode {
  email: string
  step: IUpgradeStep
  onCancelUpgradeClick: () => void
  setError: React.Dispatch<React.SetStateAction<string | null>>
  timeToResendEmail: number
  sendConfirmationEmail: () => void
  verifyConfirmationEmail: (confirmationCode: string) => void
  isLoading: boolean
}

export const VerificationCode: React.FC<IVerificationCode> = ({
  step,
  email,
  onCancelUpgradeClick,
  setError,
  timeToResendEmail,
  sendConfirmationEmail,
  verifyConfirmationEmail,
  isLoading,
}) => {
  const validationSchema = yup.object({
    code: yup.string().required(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const onSubmit = async (data: FieldValues): Promise<void> => {
    try {
      await verifyConfirmationEmail(data.code)
      setError(null)
      step.nextStep()
    } catch (error) {
      let message
      if (error instanceof Error) {
        message = error.message
      } else {
        message = 'The verification code is incorrect.'
      }
      setError(message)
    }
  }

  const resendEmailMessage =
    timeToResendEmail == 0
      ? 'Resend email'
      : `Resend in 0:${timeToResendEmail.toString().padStart(2, '0')}`

  return (
    <CustomContainer title="Upgrade to current Stellar Network">
      <Flex
        alignItems="center"
        justifyContent="center"
        direction="column"
        gap="32px"
        paddingTop="42px"
        paddingBottom="32px"
      >
        <Text
          fontSize="x-large"
          fontWeight="600"
          textAlign="center"
          maxWidth="600px"
        >
          Verify your email
        </Text>
        <EmailIcon width="75px" height="75px" />
        <Text fontSize="medium" p="0 32px" textAlign="center">
          A verification code was sent to
          <a color="primary.normal"> {email}</a>. Please, verify your email to
          continue with the account upgrade.
        </Text>
      </Flex>

      <form
        onSubmit={handleSubmit(data => {
          onSubmit(data)
        })}
      >
        <Flex w="100%" flexDir="column" alignItems="center">
          <Flex gap={1} flexDir="column">
            <FormControl isInvalid={errors.code != undefined}>
              <FormLabel alignSelf="start" fontSize="12px">
                Verification code
              </FormLabel>
              <Input
                width="312px"
                height="26px"
                fontSize="12px"
                placeholder="XXXXXXXXX"
                {...register('code')}
              />
              <FormErrorMessage fontSize="12px">
                Code is required.
              </FormErrorMessage>
            </FormControl>

            <Button
              variant="link"
              fontSize="12px"
              color="primary.normal"
              fontWeight="500"
              alignSelf="flex-start"
              isDisabled={timeToResendEmail != 0}
              onClick={async (): Promise<void> => {
                try {
                  await sendConfirmationEmail()
                  setError(null)
                } catch (error) {
                  setError((error as Error).message)
                }
              }}
            >
              Didn't receive the email? {resendEmailMessage}.
            </Button>
          </Flex>

          <Button
            type="submit"
            variant="primary"
            fontSize="md"
            fontWeight="900"
            mt="32px"
            mb="16px"
            isLoading={isLoading}
          >
            Continue to account upgrade
          </Button>
        </Flex>
      </form>

      <Flex gap={1} justifyContent="center">
        <Button
          variant="link"
          fontSize="sm"
          color="primary.normal"
          fontWeight="500"
          mt="-8px"
          onClick={onCancelUpgradeClick}
        >
          Cancel account upgrade
        </Button>
      </Flex>
    </CustomContainer>
  )
}
