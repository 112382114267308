import { Button, Container, Flex, Spinner, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CREATE_ACCOUNT_LINK } from 'utils/constants/constants'
import { Messages } from 'utils/constants/helpers'
import { formatSTR } from 'utils/formatter'

import { PathRoute } from 'components/enums/path-route'
import { RocketIcon, WalletIcon, CircleCheckPurpleIcon } from 'components/icons'
import { CustomContainer } from 'components/molecules/custom-container'

import { UserResponseData } from 'app/core/types/user'

interface IHomeTemplate {
  loading: boolean
  user?: UserResponseData
}

export const HomeTemplate: React.FC<IHomeTemplate> = ({ user }) => {
  const navigate = useNavigate()

  const balanceInSTR = formatSTR(user?.str_balance ?? 0)
  const haveEnoughBalance = balanceInSTR.isGreaterThanOrEqualTo(1)

  return (
    <Flex w="full" direction="column" p="72px 40px" alignItems="center">
      <Flex
        maxWidth="789px"
        w="90vw"
        pt="62px"
        direction="column"
        alignItems="flex-start"
        gap="12px"
      >
        <Flex gap="9px" alignItems="flex-end">
          <WalletIcon width="36px" height="36px" />
          <Text fontWeight="bold" fontSize="x-large">
            Your Account
          </Text>
        </Flex>

        <CustomContainer
          title="Stellar Balance"
          size={haveEnoughBalance ? 'large' : 'medium'}
        >
          <Flex
            direction="column"
            gap="24px"
            height="100%"
            justifyContent="center"
          >
            {!user && (
              <Flex alignItems="center" justifyContent="center">
                <Spinner size="xl" speed="0.65s" data-testid="spinner" />
              </Flex>
            )}
            {user && user.upgraded && (
              <Flex
                alignItems="center"
                justifyContent="center"
                direction="column"
                gap="12px"
                pb="62px"
                pt="50px"
              >
                <CircleCheckPurpleIcon width="62px" height="62px" />
                <Text
                  fontSize="medium"
                  fontWeight="600"
                  pt="6px"
                  textAlign="center"
                >
                  {Messages.USER_UPGRADED}
                </Text>
                <Text fontSize="14px" maxWidth="70%" textAlign="center">
                  {Messages.MANAGER_LUMENS}
                </Text>
              </Flex>
            )}
            {user && !user.upgraded && (
              <>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                >
                  <Text paddingRight="160px" fontSize="14px" fontWeight="600">
                    You own
                  </Text>
                  <Flex
                    w="100%"
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    gap="8px"
                  >
                    <RocketIcon width="40px" />
                    <Text fontSize="xx-large" fontWeight="600">
                      {`${balanceInSTR} STR`}
                    </Text>
                  </Flex>
                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                >
                  <Container
                    variant="primary"
                    bgColor="#F5F2FF"
                    px="12px"
                    py="4px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Flex alignItems="center" justifyContent="center" p="6px">
                      {haveEnoughBalance ? (
                        <Text fontSize="14px" textAlign="center">
                          {Messages.BALANCE_IN_STELLARS}
                        </Text>
                      ) : (
                        <Text fontSize="14px" textAlign="center">
                          {Messages.NO_BALANCE_ENOUGH}
                          <a target="_blank" href={CREATE_ACCOUNT_LINK}>
                            {' '}
                            new Stellar account{' '}
                          </a>
                          and buy Lumens.
                        </Text>
                      )}
                    </Flex>
                  </Container>
                </Flex>

                {haveEnoughBalance && (
                  <Flex w="100%" direction="row" justifyContent="center">
                    <Button
                      type="submit"
                      variant="primary"
                      fontSize="md"
                      fontWeight="900"
                      width="245px"
                      onClick={(): void => {
                        navigate(PathRoute.UPGRADE)
                      }}
                    >
                      Upgrade my account
                    </Button>
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </CustomContainer>
      </Flex>
    </Flex>
  )
}
