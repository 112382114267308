import { Flex, useDisclosure } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useEmail } from 'hooks/email'
import { useUser } from 'hooks/user'

import { PathRoute } from 'components/enums/path-route'
import { HomeIcon, SettingsIcon } from 'components/icons'
import { Header, ILinkItemProps } from 'components/molecules/header'

import Authentication from 'app/auth/services/auth'
import { StellarWallet } from 'interfaces/stellar-wallet'

import { UpgradeTemplate } from './template'

const linkItems: ILinkItemProps[] = [
  {
    name: 'Home',
    icon: <HomeIcon />,
    path: PathRoute.HOME,
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    path: PathRoute.SETTINGS,
  },
]

export const Upgrade: React.FC = () => {
  const { onOpen } = useDisclosure()
  const navigate = useNavigate()
  const [seeds, setSeeds] = useState<string[]>([])
  const [wallet, setWallet] = useState<StellarWallet>()

  const oldAddress = Authentication.getOldAddress()
  const userEmail = Authentication.getUserEmail()

  const { getUser, postUpgradeUser, currentUser, upgradeStatus } =
    useUser(oldAddress)

  const {
    sendConfirmationEmail,
    verifyConfirmationEmail,
    confirmationToken,
    timeToResendEmail,
    isLoading,
  } = useEmail(oldAddress)

  const readCurrentUser = useCallback(async () => {
    if (currentUser && (currentUser.upgraded || currentUser.str_balance == 0)) {
      navigate(PathRoute.HOME)
    }
  }, [currentUser, navigate])

  const readSeedGenerated = useCallback(async () => {
    if (seeds.length == 0) {
      const stellarWallet = new StellarWallet()
      const seedGenerated = stellarWallet.getMnemonic()
      setWallet(stellarWallet)
      setSeeds(seedGenerated.split(' '))
    }
  }, [setSeeds, seeds])

  useEffect(() => {
    getUser()
  }, [getUser])

  useEffect(() => {
    readCurrentUser()
  }, [readCurrentUser])

  useEffect(() => {
    readSeedGenerated()
  }, [readSeedGenerated])

  return (
    <Flex>
      <Header
        onOpen={onOpen}
        items={linkItems}
        highlightMenu={PathRoute.HOME}
      />
      <UpgradeTemplate
        user={currentUser}
        userEmail={userEmail}
        seeds={seeds}
        upgradeUser={(): void => {
          postUpgradeUser(confirmationToken, wallet)
        }}
        upgradeStatus={upgradeStatus}
        sendConfirmationEmail={sendConfirmationEmail}
        verifyConfirmationEmail={verifyConfirmationEmail}
        timeToResendEmail={timeToResendEmail}
        isLoading={isLoading}
      />
    </Flex>
  )
}
