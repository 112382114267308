import axios from 'axios'
import { MessagesError } from 'utils/constants/messages-error'

import { addToast } from '../toast'

const handleHttpError = (e: unknown, throwException = true): void => {
  let message = (e as Error).message

  if (axios.isAxiosError(e) && e.response?.data['message']) {
    message = e.response?.data['message']
    addToast({ message })
  } else {
    addToast({ message: MessagesError.UNEXPECTED_ERROR })
  }

  if (throwException) {
    throw new Error(message)
  }
}

export { handleHttpError }
