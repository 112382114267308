import { Button, Flex, Spinner, Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'

import { CircleCheckPurpleIcon, CloseIcon } from 'components/icons'

import { UpgradeStatus } from 'app/core/types/user'

import { CustomContainer } from '../custom-container'

interface IUpgradeAccount {
  upgradeStatus: UpgradeStatus
  onClickGoBack: () => void
}

const enum Title {
  inProgress = 'Upgrade in progress',
  successful = 'Upgrade successful!',
  failed = 'Upgrade failed',
}

interface IUpgradeMessage {
  icon: React.ReactNode
  headerText: string
  descriptionText: string
}

export const UpgradeAccount: React.FC<IUpgradeAccount> = ({
  upgradeStatus,
  onClickGoBack,
}) => {
  const upgradeTitle = useMemo(() => {
    if (upgradeStatus == UpgradeStatus.successful) {
      return Title.successful
    } else if (upgradeStatus == UpgradeStatus.failed) {
      return Title.failed
    } else {
      return Title.inProgress
    }
  }, [upgradeStatus])

  const UpgradeMessage: React.FC<IUpgradeMessage> = ({
    icon,
    headerText,
    descriptionText,
  }) => {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        direction="column"
        gap="32px"
        paddingTop="96px"
        paddingBottom="112px"
        height="100%"
      >
        {icon}
        <Text
          fontSize="large"
          fontWeight="600"
          textAlign="center"
          maxWidth="600px"
        >
          {headerText}
        </Text>
        <Text fontSize="14px" p="0 32px" textAlign="center">
          {descriptionText}
        </Text>

        {upgradeStatus != UpgradeStatus.inProgress && (
          <Flex
            w="100%"
            h="480px"
            alignItems="flex-end"
            direction="row"
            justifyContent="center"
            position="absolute"
          >
            <Button
              variant="link"
              fontSize="sm"
              color="primary.normal"
              fontWeight="500"
              onClick={onClickGoBack}
            >
              Go back to the Home Page
            </Button>
          </Flex>
        )}
      </Flex>
    )
  }

  const upgradeStatusDict = {
    [UpgradeStatus.successful]: {
      icon: <CircleCheckPurpleIcon width="62px" height="62px" />,
      headerText:
        'Congratulations your account was successfully upgraded to the new Stellar network!',
      descriptionText: 'Your Lumens have been transferred to the new account.',
    },
    [UpgradeStatus.failed]: {
      icon: <CloseIcon width="62px" height="62px" />,
      headerText: 'There was a problem upgrading your account',
      descriptionText:
        "We are very sorry, we've encountered an issue while upgrading your account.",
    },
    [UpgradeStatus.inProgress]: {
      icon: <Spinner size="xl" speed="0.65s" data-testid="spinner" />,
      headerText: 'Your upgrade is in progress',
      descriptionText:
        'This process may take a while. We will redirect you as soon as we are done.',
    },
    [UpgradeStatus.forbidden]: {
      icon: <CloseIcon width="62px" height="62px" />,
      headerText: 'Upgrade Unavailable',
      descriptionText: 'Your account is not eligible for upgrading.',
    },
  }

  return (
    <CustomContainer title={upgradeTitle}>
      <UpgradeMessage
        icon={upgradeStatusDict[upgradeStatus].icon}
        headerText={upgradeStatusDict[upgradeStatus].headerText}
        descriptionText={upgradeStatusDict[upgradeStatus].descriptionText}
      />
    </CustomContainer>
  )
}
