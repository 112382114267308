import { Divider, Flex, Text } from '@chakra-ui/react'
import React from 'react'

import styles from './styles.module.scss'

type ContainerSize = 'small' | 'medium' | 'large'

const sizeStyles = {
  small: styles.customContainerSmall,
  medium: styles.customContainerMedium,
  large: styles.customContainer,
}
interface ICustomContainer {
  title: string
  children: React.ReactNode
  size?: ContainerSize
}

export const CustomContainer: React.FC<ICustomContainer> = ({
  title,
  children,
  size = 'large',
}) => {
  return (
    <div className={sizeStyles[size]}>
      <Flex
        alignItems="flex-start"
        justifyContent="center"
        direction="column"
        gap="10px"
      >
        <Text fontSize="large">{title}</Text>
        <Divider size="xl" borderColor="#C7C7C7" />
      </Flex>
      {children}
    </div>
  )
}
