import {
  Alert,
  AlertIcon,
  Button,
  CloseButton,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Messages } from 'utils/constants/helpers'
import * as yup from 'yup'

import { PathRoute } from 'components/enums/path-route'

import { ReactComponent as StellarLogo } from 'app/core/resources/stellar-dark.svg'

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''
export const DEPLOY_STAGE = process.env.REACT_APP_DEPLOY_STAGE

interface ILoginTemplate {
  handleLogin(params: Hooks.UseAuthTypes.ISignIn): Promise<void>
  loading: boolean
  reCaptchaRef?: React.RefObject<ReCAPTCHA>
  getReCaptchaToken: () => string | null | undefined
  resetReCaptcha: () => void
}

enum LoginAlert {
  expired = 'expired',
  recovery = 'recovery',
}

export const LoginTemplate: React.FC<ILoginTemplate> = ({
  handleLogin,
  loading,
  reCaptchaRef,
  getReCaptchaToken,
  resetReCaptcha,
}) => {
  const navigate = useNavigate()
  const { alert } = useParams()

  const [error, setError] = useState<string | null>(null)
  const [alertState, setAlertState] = useState<string | undefined>(alert)
  const [reCaptchaIsInvalid, setReCaptchaIsInvalid] = useState<boolean>(false)

  const validationSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const onSubmit = async (data: FieldValues): Promise<void> => {
    setError(null)
    try {
      let reCaptcha
      if (DEPLOY_STAGE != 'local') {
        reCaptcha = getReCaptchaToken()
        if (!reCaptcha) {
          setReCaptchaIsInvalid(true)
          return
        }
      } else {
        reCaptcha = 'localReCaptcha'
      }

      await handleLogin({
        username: data.username,
        password: data.password,
        recaptcha_token: reCaptcha,
      })
    } catch (error) {
      let message
      if (error instanceof Error) {
        message = error.message
      } else {
        message = String(error)
      }
      setError(message)
      resetReCaptcha()
    }
  }

  const onReCAPTCHAChange = (captchaCode: string | null): void => {
    if (!captchaCode) {
      return
    }
    setReCaptchaIsInvalid(false)
  }

  return (
    <Flex
      w="full"
      px="2rem"
      flexDir="column"
      h="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexDir="column" alignSelf="center">
        {error && (
          <Flex w="100%" direction="column" mb="0.75rem">
            <Alert
              zIndex="99"
              status="error"
              p="8px 16px"
              justifyContent="space-between"
            >
              <Flex>
                <AlertIcon />
                {error}
              </Flex>
              <CloseButton
                onClick={(): void => setError(null)}
                data-testid="close-button"
              />
            </Alert>
          </Flex>
        )}
        {alertState == LoginAlert.expired && (
          <Flex w="100%" direction="column" mb="0.75rem">
            <Alert
              zIndex="99"
              status="info"
              variant="purple"
              p="8px 16px"
              justifyContent="space-between"
            >
              <Flex>
                <AlertIcon />
                {Messages.SESSION_TIMED_OUT}
              </Flex>
              <CloseButton
                onClick={(): void => setAlertState(undefined)}
                data-testid="close-button-expired"
              />
            </Alert>
          </Flex>
        )}
        {alertState == LoginAlert.recovery && (
          <Flex w="100%" direction="column" mb="0.75rem">
            <Alert
              zIndex="99"
              status="success"
              p="8px 16px"
              justifyContent="space-between"
            >
              <Flex>
                <AlertIcon />
                {Messages.RECOVER_USERNAME_SUCCESS}
              </Flex>
              <CloseButton
                onClick={(): void => setAlertState(undefined)}
                data-testid="close-button-recovery"
              />
            </Alert>
          </Flex>
        )}
        <Container
          variant="primary"
          justifyContent="center"
          p="36px 32px"
          width="470px"
          height="660px"
          boxShadow="rgba(0, 0, 0, 0.24) 0px 4px 4px;"
        >
          <StellarLogo width="100%" />
          <Text
            fontSize="3xl"
            color="black.900"
            fontWeight="400"
            mb="1.5rem"
            mt="1.5rem"
            align="center"
          >
            Sign in to Stellar's Network Upgrade Tool
          </Text>

          <form
            onSubmit={handleSubmit(data => {
              onSubmit(data)
            })}
          >
            <Flex w="full" px="32px" flexDir="column" alignItems="center">
              <FormControl isInvalid={errors.username != undefined}>
                <FormLabel alignSelf="start">Username</FormLabel>
                <Input width="100%" {...register('username')} />
                <FormErrorMessage>Username is required.</FormErrorMessage>
                <Flex gap={1} justifyContent="flex-end" mt="6px">
                  <Button
                    variant="link"
                    fontSize="12px"
                    color="primary.normal"
                    fontWeight="500"
                    onClick={(): void => {
                      navigate(PathRoute.RECOVERY)
                    }}
                  >
                    Forgot your username?
                  </Button>
                </Flex>
              </FormControl>
              <FormControl mb="24px" isInvalid={errors.password != undefined}>
                <FormLabel mt="8px" alignSelf="start">
                  Password
                </FormLabel>
                <Input type="password" {...register('password')} />
                <FormErrorMessage>Password is required.</FormErrorMessage>
              </FormControl>

              {DEPLOY_STAGE != 'local' && (
                <FormControl mb="1.5rem" isInvalid={reCaptchaIsInvalid}>
                  <Flex justifyContent="center">
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={onReCAPTCHAChange}
                      ref={reCaptchaRef}
                      data-testid="mock-recaptcha"
                    />
                  </Flex>
                  <FormErrorMessage>
                    Please verify that you are not a robot.
                  </FormErrorMessage>
                </FormControl>
              )}

              <Button
                type="submit"
                variant="primary"
                fontSize="md"
                fontWeight="900"
                width="117px"
                mt="1.5rem"
                isLoading={loading}
              >
                Log in
              </Button>
            </Flex>
          </form>
        </Container>
      </Flex>
    </Flex>
  )
}
