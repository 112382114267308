import { useEffect, useState } from 'react'

import { TimerHook } from 'app/core/types/hooks'

const TIMER_IN_SECONDS = 60

const useTimer = (): TimerHook => {
  const [currentTime, setCurrentTime] = useState(TIMER_IN_SECONDS)
  const [isTimerStarted, setIsTimerStarted] = useState(false)

  const startTimer = (): void => {
    setCurrentTime(TIMER_IN_SECONDS)
    setIsTimerStarted(true)
  }

  useEffect(() => {
    if (isTimerStarted) {
      const currentTimeInterval = setInterval(() => {
        setCurrentTime(prevCurrentTime => prevCurrentTime - 1)
      }, 1000)

      return () => clearInterval(currentTimeInterval)
    }
  }, [isTimerStarted])

  useEffect(() => {
    if (currentTime === 0) {
      setIsTimerStarted(false)
    }
  }, [currentTime])

  return {
    startTimer,
    currentTime,
  }
}

export { useTimer }
