import { VerifyConfirmationResponse } from 'app/core/types/user'
import { http } from 'interfaces/http'

const baseUrl = 'v1/users'
const authUrl = 'v1/auth'

const postSendConfirmationEmail = async (oldAddress: string): Promise<void> => {
  await http.post(`${baseUrl}/${oldAddress}/upgrade/confirmation-email`)
}

const postVerifyConfirmationEmail = async (
  oldAddress: string,
  confirmationCode: string
): Promise<VerifyConfirmationResponse> => {
  const verifyEmailRequest = {
    code: confirmationCode,
  }
  const response = await http.post(
    `${baseUrl}/${oldAddress}/upgrade/verify-email`,
    verifyEmailRequest
  )
  return response.data
}

const postSendRecoverUsernameEmail = async (
  email: string,
  reCaptchaToken: string
): Promise<void> => {
  const recoverUsernameRequest = {
    email: email,
    recaptcha_token: reCaptchaToken,
  }
  await http.post(`${authUrl}/forgot-username`, recoverUsernameRequest)
}

export {
  postSendConfirmationEmail,
  postVerifyConfirmationEmail,
  postSendRecoverUsernameEmail,
}
