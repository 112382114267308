import { StellarWallet } from 'interfaces/stellar-wallet'

export type AuthResponseData = {
  token: string
  user: {
    id: number
    username: string
    email: string
    email_verified: string
    email_unsubscribed: string
    email_euid: string
    old_stellar_address: string
    new_stellar_address: string
    str_balance: number
    crypto_balances: string
    date_joined: Date
    recover_enabled: boolean
    deleted_at: Date
    whitelisted_upgrade: boolean
    upgrade_claimed_at: Date
    upgraded_at: Date
    upgrade_transaction_id: number
    upgrade_message: string
    upgrade_error: string
  }
  wallet: {
    id: number
    lock_version: number
    created_at: Date
    updated_at: Date
    wallet_id: string
    public_key: string
    username: string
    salt: string
    kdf_params: {
      algorithm: string
      bits: number
      n: number
      r: number
      p: number
    }
    main_data: string
    keychain_data: string
  }
}

export type UserResponseData = {
  str_balance: number
  claimed: boolean
  upgraded: boolean
}

export type VerifyConfirmationResponse = {
  confirmation_token: string
}

export type UpgradeUser = {
  oldAddress: string
  stellarWallet: StellarWallet
  code?: string
}

export type PostUpgradeUser = {
  wallet: StellarWallet
  needConfirmationToken: boolean
}

export type UpgradeRequestData = {
  new_public_key: string
  signature: string
  code?: string
}

export const enum UpgradeStatus {
  inProgress = 'inProgress',
  successful = 'successful',
  failed = 'fail',
  forbidden = 'forbidden' 
}