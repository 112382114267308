import React, { useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

import { PathRoute } from 'components/enums/path-route'
import { LoginTemplate } from 'components/templates/login'

export const Login: React.FC = () => {
  const { signIn, loading } = useAuth()
  const navigate = useNavigate()
  const reCaptchaRef = useRef<ReCAPTCHA>(null)

  const handleLogin = async (
    params: Hooks.UseAuthTypes.ISignIn
  ): Promise<void> => {
    const user = await signIn(params)
    if (user) {
      navigate(PathRoute.HOME)
    }
  }

  const resetReCaptcha = (): void => {
    return reCaptchaRef.current?.reset()
  }

  const getReCaptchaToken = (): string | null | undefined => {
    return reCaptchaRef.current?.getValue()
  }

  return (
    <LoginTemplate
      handleLogin={handleLogin}
      loading={loading}
      reCaptchaRef={reCaptchaRef}
      getReCaptchaToken={getReCaptchaToken}
      resetReCaptcha={resetReCaptcha}
    />
  )
}
