import React from 'react'
import { toast, Id, ToastOptions } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { PathRoute } from 'components/enums/path-route'

import styles from './styles.module.scss'

type ToastProps = {
  /**
   * Text to be displayed inside toast body
   */
  message: string | JSX.Element
  /**
   * Link to navigate. If any, the toast message will redirect to it
   */
  link?: PathRoute
  /**
   * Toast style variants
   */
  type?: 'success' | 'error' | 'info' | 'warning' | 'default'
}

const options: ToastOptions = {
  position: 'bottom-left',
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'colored',
}

const ToastLinkNavigator: React.FC<{
  link: PathRoute
  message: string | JSX.Element
}> = ({ link, message }) => {
  return (
    <a href={link} className={styles.toastLink}>
      {message}
    </a>
  )
}

export const addToast = ({ message, type, link }: ToastProps): Id =>
  toast(link ? <ToastLinkNavigator message={message} link={link} /> : message, {
    ...options,
    type: type || 'error',
  })
