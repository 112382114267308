import { Container, Flex, List, Text, useMediaQuery } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { PathRoute } from 'components/enums/path-route'

import { ReactComponent as StellarLogo } from 'app/core/resources/stellar.svg'

import styles from './styles.module.scss'

export interface ILinkItemProps {
  name: string
  icon: ReactNode
  path: string
  alerts?: number
}

interface IHeader {
  items: ILinkItemProps[]
  highlightMenu: PathRoute
  onOpen(): void
}

export const Header: React.FC<IHeader> = ({ items, highlightMenu }) => {
  const [isLargerThanSm] = useMediaQuery('(min-width: 630px)')
  const navigate = useNavigate()

  return (
    <Flex
      h="72px"
      align="center"
      w="full"
      px="20px"
      justifyContent="space-between"
      pos="fixed"
      bg="gray.500"
      borderBottom="1px solid"
      borderColor="gray.600"
      _dark={{ borderColor: 'black.800', bg: 'black.600' }}
      zIndex="100"
    >
      <nav className={styles.menu}>
        <ul>
          {items.map(({ name, path, icon }) => (
            <List
              key={name}
              onClick={(): void => navigate(path)}
              className={
                highlightMenu == path ? styles.menuItemActive : styles.menuItem
              }
            >
              <Flex width="16px">{icon}</Flex>
              <Text
                fontSize="1xl"
                fontWeight="800"
                mb="24px"
                mt="24px"
                align="center"
              >
                {name}
              </Text>
            </List>
          ))}
        </ul>
      </nav>
      <Flex gap="16px">
        {isLargerThanSm && <StellarLogo width="96px" />}
        <Container
          variant="primary"
          px="12px"
          py="4px"
          w="190px"
          border="2px solid"
          borderColor="primary.normal"
        >
          <Flex alignItems="center" justifyContent="center">
            <Text fontSize="xs" fontWeight="bold" color="primary.normal">
              {'NETWORK UPGRADE TOOL'}
            </Text>
          </Flex>
        </Container>
      </Flex>
    </Flex>
  )
}
