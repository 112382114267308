import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { IUpgradeStep } from 'app/core/pages/upgrade/template'

import { CustomContainer } from '../custom-container'

interface IConfirmSeed {
  step: IUpgradeStep
  seeds: string[]
  setError: React.Dispatch<React.SetStateAction<string | null>>
}

export const ConfirmSeed: React.FC<IConfirmSeed> = ({
  step,
  seeds,
  setError,
}) => {
  const validationSchema = yup.object({
    firstWord: yup.string().required(),
    fifthWord: yup.string().required(),
    twelfthWord: yup.string().required(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const onSubmit = async (data: FieldValues): Promise<void> => {
    if (
      seeds[0] === data.firstWord &&
      seeds[4] === data.fifthWord &&
      seeds[11] === data.twelfthWord
    ) {
      setError(null)
      step.nextStep()
    } else {
      setError('The recovery phrase you entered is incorrect.')
    }
  }

  return (
    <CustomContainer title="Confirm recovery phrase">
      <Flex
        alignItems="flex-start"
        justifyContent="center"
        direction="column"
        pt="16px"
      >
        <Text fontSize="14px" textAlign="center">
          Please fill in the words from the indicated positions of your recovery
          phrase.
        </Text>
      </Flex>

      <form
        onSubmit={handleSubmit(data => {
          onSubmit(data)
        })}
      >
        <Flex w="100%" flexDir="column" alignItems="center" p="16px 72px">
          <FormControl
            maxWidth="308px"
            isInvalid={errors.firstWord != undefined}
          >
            <FormLabel alignSelf="start">What's the 1st word?</FormLabel>
            <Input width="100%" {...register('firstWord')} />
            <FormErrorMessage>1st word is required.</FormErrorMessage>
          </FormControl>
          <FormControl
            maxWidth="308px"
            isInvalid={errors.fifthWord != undefined}
          >
            <FormLabel mt="1.5rem" alignSelf="start">
              What's the 5th word?
            </FormLabel>
            <Input type="fifthWord" {...register('fifthWord')} />
            <FormErrorMessage>5th word is required.</FormErrorMessage>
          </FormControl>
          <FormControl
            maxWidth="308px"
            mb="1.5rem"
            isInvalid={errors.twelfthWord != undefined}
          >
            <FormLabel mt="1.5rem" alignSelf="start">
              What's the 12th word?
            </FormLabel>
            <Input type="twelfthWord" {...register('twelfthWord')} />
            <FormErrorMessage>12th word is required.</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex w="100%" flexDir="column" alignItems="center">
          <Button
            type="submit"
            variant="primary"
            fontSize="md"
            fontWeight="900"
          >
            Confirm account upgrade
          </Button>
        </Flex>
      </form>

      <Flex gap={1} justifyContent="center">
        <Button
          variant="link"
          fontSize="sm"
          color="primary.normal"
          fontWeight="500"
          mt="8px"
          onClick={step.previousStep}
        >
          Back
        </Button>
      </Flex>
    </CustomContainer>
  )
}
