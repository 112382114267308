import React, { useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { useEmail } from 'hooks/email'

import { RecoveryTemplate } from 'components/templates/recovery'

export const Recovery: React.FC = () => {
  const reCaptchaRef = useRef<ReCAPTCHA>(null)
  const { sendRecoverUsernameEmail } = useEmail()

  const getReCaptchaToken = (): string | null | undefined => {
    return reCaptchaRef.current?.getValue()
  }

  return (
    <RecoveryTemplate
      reCaptchaRef={reCaptchaRef}
      sendRecoverUsernameEmail={sendRecoverUsernameEmail}
      getReCaptchaToken={getReCaptchaToken}
    />
  )
}
