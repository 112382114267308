import { Button, Container, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { PathRoute } from 'components/enums/path-route'

import { IUpgradeStep } from 'app/core/pages/upgrade/template'

import { CustomContainer } from '../custom-container'

interface IGenerateSeed {
  step: IUpgradeStep
  seeds: string[]
}

export const GenerateSeed: React.FC<IGenerateSeed> = ({ step, seeds }) => {
  const navigate = useNavigate()

  return (
    <CustomContainer title="Your new Stellar account">
      <Flex direction="column" height="100%" pt="16px" gap="16px">
        <Flex
          alignItems="flex-start"
          justifyContent="center"
          direction="column"
          gap="12px"
        >
          <Text fontSize="14px" textAlign="left" fontWeight="bold">
            Recovery Phrase
          </Text>
          <Container
            variant="primary"
            bgColor="#F5F2FF"
            px="12px"
            py="12px"
            alignItems="center"
            justifyContent="center"
            maxWidth="789px"
          >
            <Text fontSize="14px" textAlign="left">
              <b>
                Your recovery phrase is the only way to recover your Stellar
                account and access your Lumens after the upgrade.{' '}
              </b>
              Write it down carefully and store it safely. For your safety, we
              will verify if you copied it right in the next step.
            </Text>
          </Container>
        </Flex>

        <Flex w="100%" justifyContent="center" pt="12px">
          <Container
            variant="primary"
            bgColor="#F8F8F8"
            pt="16px"
            pb="16px"
            paddingLeft="20px"
            paddingRight="20px"
          >
            <Grid
              gridAutoFlow="column"
              gridTemplate="repeat(3, 1fr) / repeat(4, 1fr)"
              gap={3}
            >
              {seeds.map((seed, index) => (
                <GridItem w="100%" h="8" key={index}>
                  <Flex
                    gap="16px"
                    w="100%"
                    h="100%"
                    alignItems="center"
                    paddingLeft="8px"
                  >
                    <Text
                      color="primary.normal"
                      fontWeight="bold"
                      userSelect="none"
                    >
                      {++index}
                    </Text>
                    <Text userSelect="none">{seed}</Text>
                  </Flex>
                </GridItem>
              ))}
            </Grid>
          </Container>
        </Flex>

        <Text fontSize="small" textAlign="center" color="#AD5700">
          Keep your recovery phrase private! Sharing it could compromise the
          security of your Stellar account.
        </Text>

        <Flex w="100%" direction="row" justifyContent="center" mt="8px">
          <Button
            variant="primary"
            fontSize="md"
            fontWeight="900"
            onClick={step.nextStep}
          >
            Continue account upgrade
          </Button>
        </Flex>

        <Flex gap={1} justifyContent="center">
          <Button
            variant="link"
            fontSize="sm"
            color="primary.normal"
            fontWeight="500"
            mt="-4px"
            onClick={(): void => {
              navigate(PathRoute.HOME)
            }}
          >
            Cancel account upgrade
          </Button>
        </Flex>
      </Flex>
    </CustomContainer>
  )
}
