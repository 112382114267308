import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { UserIcon } from 'components/icons'

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''
export const DEPLOY_STAGE = process.env.REACT_APP_DEPLOY_STAGE
interface IRecoveryTemplate {
  reCaptchaRef?: React.RefObject<ReCAPTCHA>
  sendRecoverUsernameEmail: (email: string, reCaptchaToken: string) => void
  getReCaptchaToken: () => string | null | undefined
}

export const RecoveryTemplate: React.FC<IRecoveryTemplate> = ({
  reCaptchaRef,
  sendRecoverUsernameEmail,
  getReCaptchaToken,
}) => {
  const navigate = useNavigate()
  const [reCaptchaIsInvalid, setReCaptchaIsInvalid] = useState<boolean>(false)

  const validationSchema = yup.object({
    email: yup
      .string()
      .required('This field is required.')
      .email('Please enter a valid email address.'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const onSubmit = async (data: FieldValues): Promise<void> => {
    if (DEPLOY_STAGE != 'local') {
      const reCaptchaToken = getReCaptchaToken()
      if (!reCaptchaToken) {
        setReCaptchaIsInvalid(true)
        return
      }
      sendRecoverUsernameEmail(data.email, reCaptchaToken)
    } else {
      sendRecoverUsernameEmail(data.email, "localReCaptcha")
    }
    navigate('/login/recovery')
  }

  const onReCAPTCHAChange = (captchaCode: string | null): void => {
    if (!captchaCode) {
      return
    }
    setReCaptchaIsInvalid(false)
  }

  return (
    <Flex
      w="full"
      flexDir="column"
      h="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Container
        variant="primary"
        width="470px"
        height="660px"
        boxShadow="rgba(0, 0, 0, 0.24) 0px 4px 4px;"
      >
        <Flex
          w="full"
          h="full"
          gap="16px"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          <UserIcon width="100%" />
          <Text
            fontSize="32px"
            color="black.900"
            fontWeight="400"
            align="center"
            letterSpacing="-1px"
            paddingTop="8px"
          >
            Username recovery
          </Text>

          <Text fontSize="16px" fontWeight="400" align="center" width="85%">
            Please enter your email address, and we will send the associated
            username to this address shortly.
          </Text>

          <form
            onSubmit={handleSubmit(data => {
              onSubmit(data)
            })}
          >
            <Flex w="full" px="32px" flexDir="column" alignItems="center">
              <FormControl isInvalid={errors.email != undefined}>
                <Input
                  width="100%"
                  placeholder="jane.doe@email.com"
                  {...register('email')}
                  data-testid="recovery-input"
                />
                <FormErrorMessage>
                  {errors.email?.message?.toString()}
                </FormErrorMessage>
              </FormControl>
              {DEPLOY_STAGE != 'local' && (
                <FormControl mt="16px" isInvalid={reCaptchaIsInvalid}>
                  <Flex justifyContent="center">
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={onReCAPTCHAChange}
                      ref={reCaptchaRef}
                      data-testid="recaptcha-recovery"
                    />
                  </Flex>
                  <FormErrorMessage>
                    Please verify that you are not a robot.
                  </FormErrorMessage>
                </FormControl>
              )}

              <Button
                type="submit"
                variant="primary"
                fontSize="md"
                fontWeight="900"
                width="305px"
                mt="32px"
              >
                Recover username
              </Button>
            </Flex>
          </form>

          <Flex gap={1} justifyContent="center">
            <Button
              variant="link"
              fontSize="sm"
              color="primary.normal"
              fontWeight="500"
              pt="8px"
              onClick={(): void => {
                navigate('/login')
              }}
            >
              Go back to Sign in
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}
