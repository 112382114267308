import { Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'

import Platform from 'platform'
import {
  FREIGHTER_EXTENSION_CHROME_BRAVE,
  FREIGHTER_EXTENSION_FIREFOX,
  FREIGHTER_URL,
} from 'utils/constants/constants'

import { DownloadIcon, FreighterLogo } from 'components/icons'

import { IUpgradeStep } from 'app/core/pages/upgrade/template'

import { CustomContainer } from '../custom-container'

interface IInstallFreighter {
  step: IUpgradeStep
  onCancelUpgradeClick: () => void
  upgradeUser: () => void
}

export const InstallFreighter: React.FC<IInstallFreighter> = ({
  step,
  onCancelUpgradeClick,
  upgradeUser,
}) => {
  const openLink = (): void => {
    const browser = Platform.name
    if (browser == 'Chrome' || browser == 'Brave') {
      window.open(FREIGHTER_EXTENSION_CHROME_BRAVE, '_blank')
    } else if (browser == 'Firefox') {
      window.open(FREIGHTER_EXTENSION_FIREFOX, '_blank')
    } else {
      window.open(FREIGHTER_URL, '_blank')
    }
  }

  return (
    <CustomContainer title="Download Freighter Wallet">
      <Flex
        alignItems="center"
        justifyContent="center"
        direction="column"
        gap="12px"
        p="102px 0"
      >
        <FreighterLogo />
        <Text fontSize="14px" textAlign="center" maxWidth="80%">
          Before continuing, you can add the
          <a target="_blank" href={FREIGHTER_URL} color="primary.normal">
            {' '}
            Freighter Wallet{' '}
          </a>
          to your browser for easy access to your new account.
        </Text>
        <Button
          variant="primary"
          fontSize="md"
          fontWeight="900"
          leftIcon={
            <DownloadIcon color="primary.normal" width="20px" height="20px" />
          }
          onClick={openLink}
        >
          Download Freighter
        </Button>
      </Flex>

      <Flex w="100%" direction="row" justifyContent="center" mt="16px">
        <Button
          variant="primary"
          fontSize="md"
          fontWeight="900"
          onClick={(): void => {
            upgradeUser()
            step.nextStep()
          }}
        >
          Finish upgrade
        </Button>
      </Flex>

      <Flex w="100%" direction="row" justifyContent="center">
        <Button
          variant="link"
          fontSize="sm"
          color="primary.normal"
          fontWeight="500"
          onClick={onCancelUpgradeClick}
          mt="8px"
        >
          Cancel upgrade
        </Button>
      </Flex>
    </CustomContainer>
  )
}
