import StellarHDWallet from 'stellar-hd-wallet'

export class StellarWallet {
  private mnemonic: string
  private wallet: StellarHDWallet

  constructor(
    mnemonic: string = StellarHDWallet.generateMnemonic({ entropyBits: 128 })
  ) {
    this.mnemonic = mnemonic
    this.wallet = StellarHDWallet.fromMnemonic(this.mnemonic)
  }

  getPublicKey(): string {
    return this.wallet.getPublicKey(0)
  }

  getSecretKey(): string {
    return this.wallet.getSecret(0)
  }

  mnemonicIsValid(mnemonic: string): boolean {
    return StellarHDWallet.validateMnemonic(mnemonic)
  }

  getMnemonic(): string {
    return this.mnemonic
  }

  validateWalletFromMnemonic(mnemonic: string): boolean {
    const walletFromMnemonic = StellarHDWallet.fromMnemonic(mnemonic)
    return walletFromMnemonic.getPublicKey(0) == this.wallet.getPublicKey(0)
  }
}
