import { Flex, useDisclosure } from '@chakra-ui/react'
import React, { useEffect } from 'react'

import { useUser } from 'hooks/user'

import { PathRoute } from 'components/enums/path-route'
import { HomeIcon, SettingsIcon } from 'components/icons'
import { Header, ILinkItemProps } from 'components/molecules/header'

import Authentication from 'app/auth/services/auth'

import { HomeTemplate } from './template'

const linkItems: ILinkItemProps[] = [
  {
    name: 'Home',
    icon: <HomeIcon />,
    path: PathRoute.HOME,
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    path: PathRoute.SETTINGS,
  },
]

export const Home: React.FC = () => {
  const { onOpen } = useDisclosure()

  const oldAddress = Authentication.getOldAddress()
  const { getUser, currentUser } = useUser(oldAddress)

  useEffect(() => {
    getUser()
  }, [getUser])

  return (
    <Flex>
      <Header
        onOpen={onOpen}
        items={linkItems}
        highlightMenu={PathRoute.HOME}
      />
      <HomeTemplate loading={false} user={currentUser} />
    </Flex>
  )
}
