import { createContext, useState } from 'react'

import { AxiosError } from 'axios'
import { MessagesError } from 'utils/constants/messages-error'

import Authentication from 'app/auth/services/auth'
import { decryptUserData } from 'app/auth/services/crypto'
import { AuthResponseData } from 'app/core/types/user'
import { http } from 'interfaces/http'

export const AuthContext = createContext({} as Hooks.UseAuthTypes.IAuthContext)

interface IProps {
  children: React.ReactNode
}

export const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [user, setUser] = useState<Hooks.UseAuthTypes.IUser | null>(
    Authentication.getUserFromJWT()
  )
  const [loading, setLoading] = useState(false)

  const signIn = async (
    params: Hooks.UseAuthTypes.ISignIn
  ): Promise<Hooks.UseAuthTypes.IUser | null> => {
    setLoading(true)
    try {
      const response = await http.post(`v1/users/login`, params)
      const data: AuthResponseData = response.data
      const userAuth = data.user
      if (!userAuth) {
        throw new Error()
      }

      const userData = await decryptUserData({
        encryptedMainData: data.wallet.main_data,
        encryptedKeychainData: data.wallet.keychain_data,
        salt: data.wallet.salt,
        username: data.user.username,
        password: params.password,
        kdfParams: data.wallet.kdf_params,
      })

      Authentication.setToken(data.token)
      Authentication.setKeychainData(userData.keychainData.toString())
      Authentication.setMainData(userData.mainData.toString())
      Authentication.setUserOldAddress(data.user.old_stellar_address)
      if (data.user.email) {
        Authentication.setUserEmail(data.user.email)
      }

      setUser({
        username: userAuth.username,
      })

      return data.user
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 403) {
        // Check if the message in the response data indicates the account is deleted
        if (error.response?.data?.message === 'user account deleted') {
          throw new Error(MessagesError.ACCOUNT_DELETED)
        }
        throw new Error(MessagesError.FORBIDDEN)
      }
      throw new Error(MessagesError.INVALID_CREDENTIALS)
    } finally {
      setLoading(false)
    }
  }

  const isAuthenticated = !!user

  return (
    <AuthContext.Provider
      value={{
        signIn,
        isAuthenticated,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
