import {
  Alert,
  AlertIcon,
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MessagesError } from 'utils/constants/messages-error'

import { DeleteIcon, TrashIcon } from 'components/icons'
import { CustomContainer } from 'components/molecules/custom-container'

import Authentication from 'app/auth/services/auth'
import { UserResponseData } from 'app/core/types/user'

interface ISettingsTemplate {
  user?: UserResponseData
  deleteUser: () => Promise<void>
  isLoading: boolean
}

export const SettingsTemplate: React.FC<ISettingsTemplate> = ({
  user,
  deleteUser,
  isLoading,
}) => {
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>(null)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleDeleteButton = async (): Promise<void> => {
    try {
      await deleteUser()
      Authentication.logout()
      navigate('/login')
    } catch (error) {
      setError(MessagesError.UNEXPECTED_ERROR)
    }
    onClose()
  }

  return (
    <Flex w="full" direction="column" p="72px 40px" alignItems="center">
      <Flex
        maxWidth="789px"
        w="90vw"
        pt="62px"
        direction="column"
        alignItems="flex-start"
        gap="12px"
      >
        {error && (
          <Flex w="100%" direction="column">
            <Alert
              zIndex="99"
              status="error"
              p="8px 16px"
              justifyContent="space-between"
            >
              <Flex>
                <AlertIcon />
                {error}
              </Flex>
              <CloseButton
                onClick={(): void => setError(null)}
                data-testid="close-button"
              />
            </Alert>
          </Flex>
        )}
        <Text fontWeight="bold" fontSize="40px">
          Settings
        </Text>

        <Flex gap="9px" alignItems="flex-end">
          <DeleteIcon width="36px" height="36px" />
          <Text fontWeight="bold" fontSize="x-large">
            Delete account
          </Text>
        </Flex>

        <CustomContainer title="Delete account" size="small">
          <Flex
            direction="column"
            gap="24px"
            height="100%"
            justifyContent="center"
          >
            {user?.upgraded ? (
              <Flex mt="48px" mb="24px" direction="column" gap="12px">
                <Text fontSize="14px" textAlign="center" variant="warning">
                  By deleting your account, you will not be able to access this
                  tool again.
                  <a> This action cannot be undone.</a>
                </Text>
                <Button
                  colorScheme="red"
                  fontSize="medium"
                  fontWeight="900"
                  padding="12px"
                  alignSelf="center"
                  onClick={onOpen}
                >
                  Delete my account
                </Button>
              </Flex>
            ) : (
              <Text fontSize="14px" textAlign="center">
                This action is only available if you have
                <a color="primary.normal"> upgraded your account.</a>
              </Text>
            )}
          </Flex>
        </CustomContainer>

        <Button
          type="submit"
          variant="primary"
          fontSize="medium"
          fontWeight="900"
          padding="12px"
          mt="22px"
          alignSelf="center"
          onClick={(): void => {
            Authentication.logout()
            navigate('/login')
          }}
        >
          Sign out
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay padding="12px" />
        <ModalContent>
          <ModalHeader fontSize="18px">
            Are you sure you want to delete your account?
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <Text fontSize="14px" textAlign="left">
              Deleting your account is a permanent action and cannot be undone.
              Once your account is deleted, you will lose access to this
              account.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              fontSize="medium"
              fontWeight="900"
              padding="12px"
              alignSelf="center"
              onClick={handleDeleteButton}
              leftIcon={<TrashIcon />}
              isLoading={isLoading}
              data-testid="delete-button"
            >
              Delete my account
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
