import { Button, Flex, Spinner, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CREATE_ACCOUNT_LINK } from 'utils/constants/constants'

import { PathRoute } from 'components/enums/path-route'
import { RocketIcon, ArrowDownIcon, StellarIcon } from 'components/icons'

import { IUpgradeStep } from 'app/core/pages/upgrade/template'

import { CustomContainer } from '../custom-container'

interface IStartUpgrade {
  balanceInSTR?: string
  balanceInXLM?: string
  step: IUpgradeStep
  needSendEmail: boolean
  setError: React.Dispatch<React.SetStateAction<string | null>>
  sendConfirmationEmail: () => void
  isLoading: boolean
}

export const StartUpgrade: React.FC<IStartUpgrade> = ({
  balanceInSTR,
  balanceInXLM,
  step,
  needSendEmail,
  setError,
  sendConfirmationEmail,
  isLoading,
}) => {
  const navigate = useNavigate()

  return (
    <CustomContainer title="Start upgrade">
      <Flex direction="column" height="100%" pt="16px" gap="16px">
        <Flex
          alignItems="center"
          justifyContent="center"
          direction="column"
          pt="8px"
        >
          <Text fontSize="14px" textAlign="center">
            By upgrading your account, you will receive a
            <a target="_blank" href={CREATE_ACCOUNT_LINK}>
              {' '}
              new Stellar{' '}
            </a>
            account in the current network. Your funds will be converted from
            STR to XLM and sent to this new account. Below is the Lumens amount
            you're set to receive:
          </Text>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          direction="column"
          pt="8px"
        >
          <Text paddingRight="160px" fontSize="14px" fontWeight="600">
            You own
          </Text>
          <Flex
            w="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap="8px"
          >
            <RocketIcon data-testid="rocket-icon" />
            {balanceInSTR ? (
              <Text fontSize="xx-large" fontWeight="600">
                {`${balanceInSTR} STR`}
              </Text>
            ) : (
              <Spinner size="xl" speed="0.65s" data-testid="spinner" />
            )}
          </Flex>
        </Flex>

        <Flex w="100%" justifyContent="center">
          <ArrowDownIcon height="40px" data-testid="arrow-down-icon" />
        </Flex>

        <Flex alignItems="center" justifyContent="center" direction="column">
          <Text paddingRight="160px" fontSize="14px" fontWeight="600">
            You will get
          </Text>
          <Flex
            w="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap="8px"
          >
            <StellarIcon
              height="40px"
              width="40px"
              data-testid="stellar-icon"
            />
            {balanceInXLM ? (
              <Text fontSize="xx-large" fontWeight="600">
                {`${balanceInXLM} XLM`}
              </Text>
            ) : (
              <Spinner size="xl" speed="0.65s" data-testid="spinner" />
            )}
          </Flex>
        </Flex>

        <Flex w="100%" direction="row" justifyContent="center" mt="32px">
          <Button
            variant="primary"
            fontSize="md"
            fontWeight="900"
            onClick={async (): Promise<void> => {
              if (needSendEmail) {
                try {
                  await sendConfirmationEmail()
                  step.nextStep()
                } catch (error) {
                  setError((error as Error).message)
                }
              } else {
                step.nextStep()
              }
            }}
            isLoading={isLoading}
          >
            Start account upgrade
          </Button>
        </Flex>

        <Flex gap={1} justifyContent="center">
          <Button
            variant="link"
            fontSize="sm"
            color="primary.normal"
            fontWeight="500"
            onClick={(): void => {
              navigate(PathRoute.HOME)
            }}
          >
            Go back to Account Balance
          </Button>
        </Flex>
      </Flex>
    </CustomContainer>
  )
}
