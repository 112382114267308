import { useState } from 'react'

import { useTimer } from 'hooks/timer'
import { handleHttpError } from 'utils/http'

import {
  postSendConfirmationEmail,
  postSendRecoverUsernameEmail,
  postVerifyConfirmationEmail,
} from 'app/auth/services/email'
import { EmailHook } from 'app/core/types/hooks'

const useEmail = (userOldNetworkAddress?: string): EmailHook => {
  const { currentTime, startTimer } = useTimer()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [confirmationToken, setConfirmationToken] = useState<string>('')
  const timeToResendEmail = currentTime

  const sendConfirmationEmail = async (): Promise<void> => {
    if (userOldNetworkAddress) {
      try {
        setIsLoading(true)
        await postSendConfirmationEmail(userOldNetworkAddress)
        setIsLoading(false)
        startTimer()
      } catch (e) {
        setIsLoading(false)
        handleHttpError(e, true)
      }
    }
  }

  const verifyConfirmationEmail = async (
    confirmationCode: string
  ): Promise<void> => {
    if (userOldNetworkAddress) {
      try {
        setIsLoading(true)
        const response = await postVerifyConfirmationEmail(
          userOldNetworkAddress,
          confirmationCode
        )
        setConfirmationToken(response.confirmation_token)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        handleHttpError(e, true)
      }
    }
  }

  const sendRecoverUsernameEmail = async (
    email: string,
    reCaptchaToken: string
  ): Promise<void> => {
    try {
      setIsLoading(true)
      await postSendRecoverUsernameEmail(email, reCaptchaToken)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      handleHttpError(e, false)
    }
  }

  return {
    sendConfirmationEmail,
    verifyConfirmationEmail,
    sendRecoverUsernameEmail,
    confirmationToken,
    timeToResendEmail,
    isLoading,
  }
}

export { useEmail }
