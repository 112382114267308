import {
  UpgradeRequestData,
  UpgradeUser,
  UserResponseData,
} from 'app/core/types/user'
import { http } from 'interfaces/http'

import { generateMessage, signMessage } from '../crypto'

const baseUrl = 'v1/users'

const getUserByOldAddress = async (
  oldAddress: string
): Promise<UserResponseData> => {
  const response = await http.get(`${baseUrl}/${oldAddress}`)
  return response.data
}

const postUserUpgrade = async ({
  oldAddress,
  stellarWallet,
  code,
}: UpgradeUser): Promise<void> => {
  const messageSecret = process.env.REACT_APP_VERIFY_SIGNATURE_SECRET_KEY || ''

  const message = await generateMessage(
    oldAddress,
    stellarWallet.getPublicKey(),
    messageSecret
  )
  const signature = signMessage(stellarWallet.getSecretKey(), message)

  const upgradeRequest: UpgradeRequestData = {
    new_public_key: stellarWallet.getPublicKey(),
    signature: signature,
    code: code,
  }

  await http.post(`${baseUrl}/${oldAddress}/upgrade`, upgradeRequest)
}

const softDeleteUser = async (oldNetworkAddress: string): Promise<void> => {
  await http.delete(`${baseUrl}/${oldNetworkAddress}`)
}

export { getUserByOldAddress, postUserUpgrade, softDeleteUser }
