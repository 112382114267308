import { PathRoute } from 'components/enums/path-route'

import { Home } from '../pages/home'
import { Settings } from '../pages/settings'
import { Upgrade } from '../pages/upgrade'
import { AppRoute } from './types'

export const coreRoutes: AppRoute[] = [
  {
    path: PathRoute.HOME,
    component: Home,
    isPrivate: true,
  },
  {
    path: PathRoute.UPGRADE,
    component: Upgrade,
    isPrivate: true,
  },
  {
    path: PathRoute.SETTINGS,
    component: Settings,
    isPrivate: true,
  },
]
